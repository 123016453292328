export enum ProductAttributeField {
  RANGE = 'range',
  VOLUME = 'volume',
  BATTERY = 'battery',
  WATER_RESISTANCE = 'water-resistance',
  DIMENSIONS = 'dimensions',
  DIMENSIONS_IMPERIAL = 'dimensions-imperial',
  DIMENSIONS_METRIC = 'dimensions-metric',
  // BigC site.searchProducts can't search on product level type :shrug:, hence using this custom field
  BIGCOMMERCE_PRODUCT_TYPE = 'bigcommerce_product_type',
  SUBSCRIPTION_TIER = 'subscription_tier',
  SUBSCRIPTION_TERM = 'subscription_term',
  HIDE_COMPARISON_TABLE = 'hide-product-comparison-table',
  SUBSCRIPTION_PDP_DESCRIPTION = 'subscription-pdp-description',
  SUBSCRIPTION_PDP_FOOTNOTE = 'subscription-pdp-footnote',
  SUBSCRIPTION_CART_TEXT = 'subscription-cart-text',
  TEMPLATE = 'template',
  PRODUCT_TYPE = 'Product Type',
  PDP_COLOR = 'pdp-color',
  EXCLUDE_FROM_AB_TEST = 'exclude-from-ab-test',
  HIDE_PROMOTION_MESSAGE_BAR = 'hide-promotion-message-bar-on-pdp',
  SUBSCRIPTION_UPSELL_TITLE = 'subscription-upsell-title',
  SUBSCRIPTION_UPSELL_PRICE = 'subscription-upsell-price',
  PRODUCT_BADGE = 'product-badge',
  PRODUCT_BADGE_TEXT_BG_COLOR = 'product-badge-text-bg-color',
  PRODUCT_PILL_BADGE = 'product-pill-badge',
  PRODUCT_PILL_BADGE_TEXT_BG_COLOR = 'product-pill-badge-text-bg-color',
  PRODUCT_GROUP = 'reviews-product-group',
  WEIGHT = 'weight',
  AVAILABILITY = 'Availability',
  PACK_SIZE = 'pack-size',
  COLOR = 'Color',
  VALUE_PROPS = 'value-props',
  SHOW_NEARBY_RETAIL_PARTNERS = 'show-nearby-retail-partners',
}

export enum ProductDetailAttributeField {
  PROMOTION_HEADING = 'product-promotion-heading',
  PROMOTION_DESCRIPTION = 'product-promotion-description',
}
