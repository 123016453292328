import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import { NextRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { i18n } from 'next-i18next.config';
import { ProductJsonLdProps } from 'next-seo';
import { ParsedUrlQuery } from 'querystring';
import React, { ChangeEvent, PropsWithChildren } from 'react';

import { Image, Video } from '@commerce/types/common';
import type {
  JasperProductDetails,
  Product,
  ProductImage,
  ProductPrices,
  ProductSearchFilter,
  SearchProductsHookBody,
  TransformedAssets,
  ValueProps,
} from '@commerce/types/product';
import { CategoryTreeItem, CategoryTreeItemWithSeoAndSort } from '@commerce/types/site';
import { ProductPageContent } from '@components/common/types/Page';
import { NtConfigurable } from '@components/screen/types';
import Skeleton from '@components/ui/Skeleton/Skeleton';
import PremiumMapping from '@config/premium-mapping.json';
import { SearchProductsSortInput } from '@framework/schema';
import { StorefrontFilter } from '@framework/types/site';
import { isValidSearchProductsSortInput } from '@framework/validate';
import callApiRoutes from '@lib/api/call-api-routes';
import getEnumKeyByEnumValue from '@lib/get-enum-key-by-value';
import getPercentage from '@lib/get-percentage';
import { stringifyArray, stringifyObject, urlEncode } from '@lib/http';
import {
  extractImageProvider,
  getContentfulImgSet,
  ImageOptions,
  ImageSet,
  renderImageTag,
  renderResponsiveImage,
} from '@lib/image';
import insertToArray from '@lib/insert-to-array';
import { isUSLocale, originalLocaleFormat, SUPPORTED_LOCALES } from '@lib/locales';
import { updateQueryParam } from '@lib/query-string';
import rangeMap from '@lib/range-map';
import { Nullable } from '@lib/utility-types';
import { isVideo, renderVideo } from '@lib/video';

import {
  BannerCategorySlugs,
  ImageAspectRatio,
  ProductAttributeField,
  ProductBrand,
  ProductCategorySlug,
  ProductDescriptionSlugs,
  ProductDescriptionTitleSlugs,
  ProductDetailAttributeField,
  ProductEntities,
  ProductImageType,
  ProductSearchFilterType,
  ProductViewTemplate,
  RelationType,
  SubscriptionTerm,
  SubscriptionTier,
  SubscriptionType,
} from './enums';
import { ListingAd } from './ProductListing/ListingAd';
import { ListingSpacer } from './ProductListing/ListingSpacer/ListingSpacer';
import ProductTile from './ProductView/ProductTile/ProductTile';
import { Badge, ComparisonProduct, PackAvailability, PageQuery, Price, ProductDetails, ProductFilter } from './types';
import { AdCard, AdSpacer } from './types/Ads';
import { SubscriptionInfo, SubscriptionMapping } from './types/SubscriptionMapping';

export const comparisonTableAttributes = [
  ProductAttributeField.RANGE,
  ProductAttributeField.VOLUME,
  ProductAttributeField.BATTERY,
  ProductAttributeField.WATER_RESISTANCE,
  ProductAttributeField.DIMENSIONS,
  ProductAttributeField.WEIGHT,
];

export const sortOptions = [
  SearchProductsSortInput.Newest,
  SearchProductsSortInput.Featured,
  SearchProductsSortInput.HighestPrice,
  SearchProductsSortInput.LowestPrice,
  SearchProductsSortInput.BestSelling,
  SearchProductsSortInput.BestReviewed,
];

// the way Jasper markdown data is currently setup,
// some of them are multiline text, some are html string, some are markdown (needs to be parsed, hence `parsable: true`)
export const jasperMarkdownFields = [
  { slug: ProductAttributeField.SUBSCRIPTION_CART_TEXT, parsable: false },
  { slug: ProductAttributeField.SUBSCRIPTION_PDP_DESCRIPTION, parsable: false },
  { slug: ProductAttributeField.SUBSCRIPTION_PDP_FOOTNOTE, parsable: false },
  { slug: ProductDetailAttributeField.PROMOTION_DESCRIPTION, parsable: false },
  { slug: ProductDetailAttributeField.PROMOTION_HEADING, parsable: false },
];

export const PLP_PAGE_SIZE = 24;

export const PLP_IMAGE_SIZE = 360;

export const getPremiumMap = (locale?: string): SubscriptionMapping =>
  (locale && PremiumMapping[originalLocaleFormat(locale)]) || PremiumMapping.default || {};

export const getPremiumSKU = (
  mapping: SubscriptionMapping,
  tier: SubscriptionTier,
  term = SubscriptionTerm.YEAR,
  type = SubscriptionType.STANDALONE,
  brand = ProductBrand.TILE
): string => {
  // eslint-disable-next-line no-restricted-syntax
  for (const sku of Object.keys(mapping)) {
    const { term: curTerm, tier: curTier, type: curType, brand: curBrand } = mapping[sku];
    if (tier === curTier && term === curTerm && type === curType && brand === curBrand) {
      return sku;
    }
  }

  return '';
};

// premiumTier from TS has different format from the tier in Jasper/chargebee...
// for now we'll just use it for keyword matching to determine associated jasper subscription plan
// future TODO: match based on chargebee plan id: load chargebee plans, get the id and tier (this should be consistent with Jasper tier)
export const getPremiumBreakdownFromTsTier = (
  tsTier: string,
  locale?: string
): (SubscriptionInfo & { sku: string }) | null => {
  const mapping = getPremiumMap(locale);
  // eslint-disable-next-line no-restricted-syntax
  for (const sku of Object.keys(mapping)) {
    const { tier, term, type, brand } = mapping[sku];
    // TS only has tile premium
    if (tsTier.toLowerCase().includes(tier.toLowerCase()) && brand === ProductBrand.TILE) {
      return {
        sku,
        tier,
        term,
        type,
        brand,
      };
    }
  }

  return null;
};

export const hasSubscriptions = (locale?: string) => {
  const localeSkus = getPremiumMap(locale);
  return !isEmpty(localeSkus);
};

export const getProductBySKU = async (sku: string, locale: string): Promise<any> =>
  callApiRoutes(`/api/catalog/products/${sku}?locale=${locale}`, undefined, true);

export function getPackSizeFromCustomField(product: Product): string {
  const packSize = product.customFields?.[ProductAttributeField.PACK_SIZE];
  return packSize || '1';
}

export function getProductImages(images: ProductImage[] = []): {
  defaultImage: ProductImage | null;
  carouselImages: ProductImage[];
  lifestyleImages: ProductImage[];
  colorImage: ProductImage;
  newPdpImages: ProductImage[];
  outlineImage: ProductImage | null; // outline image is optional for all products
} {
  const carouselImages = images.filter(({ type, isThumbnail }) => type === ProductImageType.CAROUSEL && !isThumbnail);
  // fallback to 1st carouselImage when undefined
  const defaultImage = images.filter(({ isThumbnail }) => !!isThumbnail)[0] || carouselImages[0] || null;
  // find `Color` image type, fallback to thumbnail when undefined
  const colorImage = images.filter(({ type }) => type === ProductImageType.COLOR)[0] || defaultImage;
  // find `Outline` image type, fallback to null when undefined
  const outlineImage = images.filter(({ type }) => type === ProductImageType.OUTLINE)[0] || null;

  return {
    defaultImage,
    colorImage,
    outlineImage,
    // fallback to include thumbnail img, if no other images
    carouselImages: sortBy(carouselImages?.length > 0 ? carouselImages : [defaultImage], 'order'),
    lifestyleImages: sortBy(
      images.filter(({ type }) => type === ProductImageType.LIFESTYLE),
      'order'
    ),
    newPdpImages: sortBy(
      images.filter(({ type }) => type === ProductImageType.NEW),
      'order'
    ),
  };
}

const removeHtmlTags = (html: string): string => html.replace(/<[^>]+>|&nbsp;/g, '');

export function getProductInfo(product: Product) {
  const { details, prices, customFields } = product;
  const pricing = getPricingObject(prices);
  const { defaultImage, carouselImages, lifestyleImages, outlineImage, newPdpImages } = getProductImages(
    product.images
  );
  return {
    sku: product.sku,
    slug: product.slug,
    name: product.name,
    description: removeHtmlTags(product.description),
    htmlDescription: product.description,
    packSize: getPackSizeFromCustomField(product),
    badge: getProductBadge(customFields),
    pillBadge: getProductBadge(customFields, true),
    pricing,
    currencyCode: product.price.currencyCode,
    defaultImage,
    details,
    carouselImages,
    lifestyleImages,
    newPdpImages,
    outlineImage,
    productDetails: getProductDetails(details),
    valueProps: getValueProps(customFields),
  };
}

export const getProductImgSet = ({ alt, small, medium, large }: ProductImage): ImageSet => ({
  default: {
    sm: { url: small, alt },
    md: { url: medium, alt },
    original: { url: large, alt },
  },
});

export function generateImageGallery(images: ProductImage[], className: string) {
  return images.map(
    (image) =>
      image &&
      renderResponsiveImage(getProductImgSet(image), { aspectRatio: ImageAspectRatio.PRODUCT_CAROUSEL, className })
  );
}

export const generateRelatedProductsGridDom = (
  relatedProducts: Product[],
  selectedProduct: Product,
  onSelect: (sku: string) => void
) =>
  relatedProducts.map((product) => {
    const { defaultImage } = getProductImages(product.images);
    return (
      <ProductTile
        checked={product.id === selectedProduct.id}
        key={product.sku!}
        image={defaultImage}
        sku={product.sku!}
        onSelect={onSelect}
      />
    );
  });

export const getProductPromotion = ({ details: { markdownAttributes } }: Product) => {
  return {
    heading: markdownAttributes[ProductDetailAttributeField.PROMOTION_HEADING] ?? '',
    description: markdownAttributes[ProductDetailAttributeField.PROMOTION_DESCRIPTION] ?? '',
  };
};

export const getPackSizeOptionsSKU = (variants: Product[], selectedProduct: Product): PackAvailability => {
  const defaultPackSize = {
    [getPackSizeFromCustomField(selectedProduct)]: {
      sku: selectedProduct.sku,
      ...getPricingObject(selectedProduct.prices),
      isInStock: selectedProduct.inventory.isInStock,
    },
  };

  const packSizes = variants.reduce((acc, variant: Product) => {
    const { packSize, sku } = getProductInfo(variant);
    const { isInStock } = variant.inventory;

    if (packSize) {
      const currentMapping = acc[packSize];
      // overwrite mapping if it is empty
      // or if current non-selected option is OOS and there is another inStock packSize option
      if (!currentMapping || (currentMapping?.sku !== selectedProduct.sku && !currentMapping.isInStock && isInStock)) {
        acc[packSize as string] = { sku, ...getPricingObject(variant.prices), isInStock };
      }
    }

    return acc;
  }, defaultPackSize);

  return mapValues(packSizes, ({ sku, price, currencyCode, isInStock }) => ({ sku, price, currencyCode, isInStock }));
};

export const getSalePrice = (prices: ProductPrices) =>
  prices?.salePrice ? prices?.salePrice.value : prices?.price.value;

export const getOGPrice = (prices: ProductPrices) => {
  const retailPrice = prices?.retailPrice?.value ?? undefined;
  if (retailPrice === getSalePrice(prices)) {
    return undefined;
  }
  return retailPrice;
};

export function getPricingObject(pricing: ProductPrices): Price {
  return {
    price: getSalePrice(pricing),
    originalPrice: getOGPrice(pricing),
    currencyCode: pricing?.price.currencyCode,
  };
}

export const SubscriptionLogo = ({ tier, ...rest }: PropsWithChildren<{ tier: SubscriptionTier }>) => {
  const { t } = useTranslation(['common']);

  switch (tier) {
    case SubscriptionTier.PREMIUM:
      return <img src="/svgs/Premium_Logo.svg" alt={t('common:premiumBadgeAltText')} {...rest} />;
    case SubscriptionTier.PROTECT:
      return <img src="/svgs/PremiumProtect_Logo.svg" alt={t('common:protectBadgeAltText')} {...rest} />;
    default:
      return null;
  }
};

export function isFreeShipping(locale: string): boolean {
  if (isUSLocale(locale)) {
    return true;
  }
  return false;
}

export function getValueProps(data?: Record<string, string>): ValueProps[] {
  if (!data) {
    return [];
  }

  const valuePropsString = data[ProductAttributeField.VALUE_PROPS];
  if (!valuePropsString) {
    return [];
  }

  return valuePropsString
    .split(',')
    .map((item) => {
      const trimmedItem = item.trim();
      const words = trimmedItem.split(' ');
      const name = words
        .map((word, index) =>
          index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join('')
        .replace(/[^a-zA-Z0-9]/g, '');
      return { name, text: trimmedItem };
    })
    .sort((a, b) => b.name.localeCompare(a.name));
}

export function getProductBadge(customFields?: Record<string, string>, pill = false): Badge | null {
  const [badgeKey, colorKey] = pill
    ? [ProductAttributeField.PRODUCT_PILL_BADGE, ProductAttributeField.PRODUCT_PILL_BADGE_TEXT_BG_COLOR]
    : [ProductAttributeField.PRODUCT_BADGE, ProductAttributeField.PRODUCT_BADGE_TEXT_BG_COLOR];
  if (customFields?.[badgeKey]) {
    const [textColor, bgColor] = customFields[colorKey] ? customFields[colorKey].split('/') : [];
    return {
      text: customFields[badgeKey] || '',
      color: textColor || '',
      bgColor: bgColor || '',
      isPill: pill,
    };
  }
  return null;
}

export function getProductDetails({ entities }: JasperProductDetails): ProductDetails | null {
  const titleEntity = entities[ProductEntities.PRODUCT_DESCRIPTION_TITLE];
  const contentEntity = entities[ProductEntities.PRODUCT_DESCRIPTION];
  if (titleEntity || contentEntity) {
    const contents =
      contentEntity
        ?.sort(
          ({ attributes: attrA }, { attributes: attrB }) =>
            parseInt(attrA[ProductDescriptionSlugs.ORDER], 10) - parseInt(attrB[ProductDescriptionSlugs.ORDER], 10)
        )
        .map(({ attributes }) => ({
          header: attributes[ProductDescriptionSlugs.HEADER],
          richHtmlContent: attributes[ProductDescriptionSlugs.CONTENT],
        })) || [];
    return {
      title: titleEntity?.[0]?.attributes[ProductDescriptionTitleSlugs.TITLE] || '',
      contents,
    };
  }
  return null;
}

const getUpdatedFilterSelection = (e: ChangeEvent, { filters }: ProductSearchFilter): (string | number)[] => {
  const currentSelection = filters
    .filter(({ selected }) => selected)
    .map(({ value }) => value)
    .map((value) => String(value));
  const updated = [...currentSelection];
  const selectedKey = (e.target as HTMLInputElement)?.value;
  const isChecked = (e.target as HTMLInputElement)?.checked;
  if (isChecked) {
    updated.push(selectedKey);
  } else {
    const indexToRemove = updated.indexOf(selectedKey);
    if (indexToRemove > -1) {
      updated.splice(indexToRemove, 1);
    }
  }
  return updated;
};

const sortCategoryOptions = (categoryFilter: ProductSearchFilter, sortOrder?: string[]): ProductSearchFilter => {
  if (!sortOrder?.length) {
    return categoryFilter;
  }
  const sortedFilter = { ...categoryFilter };
  sortedFilter.filters.sort((a, b) => sortOrder.indexOf(String(a.value)) - sortOrder.indexOf(String(b.value)));
  return sortedFilter;
};

export function generateFilters(
  searchFilters: ProductSearchFilter[],
  updateFilter: (key: string, values: (number | string)[]) => void,
  optionSortOrders?: { [key: string]: string[] }
): ProductFilter[] {
  return searchFilters.map((searchFilter) => {
    const filter =
      searchFilter.type === ProductSearchFilterType.CATEGORY
        ? sortCategoryOptions(searchFilter, optionSortOrders?.category)
        : searchFilter;
    const onChange = (e: ChangeEvent) => {
      const updatedValues = getUpdatedFilterSelection(e, filter);
      updateFilter(filter.slug, updatedValues);
    };
    return { ...filter, onChange };
  });
}

export function parseComparisonProducts(
  products: Product[],
  categories: CategoryTreeItem[] // to grab comparison table header (category name) and slug (for redirect url)
): ComparisonProduct[] {
  const headerMapping = categories.reduce((mapping, { slug, name }) => {
    if (slug && Object.values(ProductCategorySlug).includes(slug as ProductCategorySlug)) {
      const key = getEnumKeyByEnumValue(ProductCategorySlug, slug);
      return { ...mapping, [key]: name };
    }
    return mapping;
  }, {} as Record<string, string>);

  const headerKeys = Object.keys(ProductCategorySlug)
    .map((key) => {
      return typeof key === 'string' ? key : '';
    })
    .filter((value) => !!value);

  return uniqBy(
    products.map((product: Product) => {
      const { defaultImage, outlineImage } = getProductImages(product.images);
      const productGroup = product.customFields?.[ProductAttributeField.PRODUCT_GROUP] || '';

      const headerKey = headerKeys.find((type) => productGroup.includes(type)) || '';

      return {
        image: defaultImage,
        outlineImage,
        productGroup,
        customFields: product.customFields || {},
        categorySlug: (ProductCategorySlug as any)[headerKey] || '',
        header: {
          key: headerKey || '',
          title: (headerKey && headerMapping[headerKey]) || '',
        },
      };
    }, [] as ComparisonProduct[]),
    'productGroup'
  );
}

export function isSubscription({ customFields, type }: Product): boolean {
  return type === 'Digital' || customFields?.[ProductAttributeField.BIGCOMMERCE_PRODUCT_TYPE] === 'Digital';
}

export function generateProductJsonLd(product: Product): ProductJsonLdProps {
  const productInfo = getProductInfo(product);

  return {
    productName: productInfo.name,
    description: product.description
      ?.replace(/(\r\n|\n|\r)/gm, ' ') // remove line breaks
      .replace(/<[^>]*>/g, ' ') // remove HTML tags
      .replace(/\s{2,}/g, ' ') // remove double spaces
      .trim(),
    images: [productInfo.defaultImage?.url || ''],
    brand: product.brand || ProductBrand.TILE,
    ...(product.reviewSummary?.numberOfReviews > 0 && {
      aggregateRating: {
        ratingValue: (product.reviewSummary.summationOfRatings / product.reviewSummary.numberOfReviews).toFixed(1),
        reviewCount: String(product.reviewSummary.numberOfReviews),
      },
    }),
    sku: product.sku,
    offers: [
      {
        price: String(productInfo.pricing.price),
        priceCurrency: productInfo.currencyCode,
        itemCondition: 'https://schema.org/NewCondition',
        availability: product.inventory.isInStock ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
        seller: {
          name: 'Tile',
        },
      },
    ],
  };
}

export function isProductGiftBox({ categories }: Product): boolean {
  // gift box should not be assign to any other categories
  if (categories?.find(({ path }) => path === '/gift-boxes/')) {
    return true;
  }

  return false;
}

export function isTileGps(product?: Product | null): boolean {
  return (
    product?.customFields?.[ProductAttributeField.PRODUCT_GROUP] === 'tile-gps' ||
    product?.brand === ProductBrand.TILE_PLUS
  );
}

export const getProductsBySKUs = async (
  skus: string[],
  locale?: string,
  query?: Record<string, string>
): Promise<Product[]> => {
  if (!skus?.length) {
    return [];
  }

  const searchParams = new URLSearchParams({
    ...query,
    skus: skus.join(','),
    locale: locale ?? '',
  });

  return (
    (await callApiRoutes(`/api/catalog/products?${searchParams.toString()}`, undefined, true)).data?.products || []
  );
};

export const getProductsByIds = async (
  ids: number[],
  locale?: string,
  query?: Record<string, string>
): Promise<Product[]> => {
  if (!ids?.length) {
    return [];
  }

  const searchParams = new URLSearchParams({
    ...query,
    ids: ids.join(','),
    locale: locale ?? '',
  });

  return (
    (await callApiRoutes(`/api/catalog/products?${searchParams.toString()}`, undefined, true)).data?.products || []
  );
};

export const checkProductAvailability = async (sku: string, locale?: string) =>
  !!(await callApiRoutes(`/api/catalog/products/${sku}/available?locale=${locale}`, undefined, true)).data?.isAvailable;

export const getRelatedProducts = async (sku: string, locale?: string, query?: Record<string, string>) => {
  const searchParams = new URLSearchParams({
    ...query,
    locale: locale ?? '',
  });

  return (
    (await callApiRoutes(`/api/catalog/products/${sku}/related-products?${searchParams.toString()}`, undefined, true))
      .data || []
  );
};

// alternative to useSearch with more state/flow control, calling the endpoint directly
// drawback is no SWR here
export const searchProducts = async (
  body: SearchProductsHookBody & { locale: string }
): Promise<{
  data: {
    products: Product[];
    hasNextPage?: boolean;
    afterCursor?: string;
    filters?: ProductSearchFilter[];
    total: number;
  };
} | null> => {
  const { categories, attributes, pageReady, ...rest } = body;

  if (!pageReady) {
    return null;
  }

  const cleanedQuery: Record<string, string | number | boolean | undefined> = pickBy(
    {
      ...rest,
      categories: stringifyArray(categories),
      attributes: stringifyObject(attributes),
    },
    (value) => !!value
  );

  const query = urlEncode(cleanedQuery, true);
  return callApiRoutes(`/api/catalog/products?${query}`, { headers: { 'Content-Type': 'application/json' } });
};

export const resetFilterQueryParam = (router: NextRouter, fieldsToReset: string[] = []) => {
  const { query, asPath } = router;
  const { categorySlug, ...cleanedQuery } = query;
  const pathname = asPath.split('?')[0];
  const updatedQuery = { ...cleanedQuery };
  fieldsToReset.forEach((queryField) => {
    delete updatedQuery[queryField];
  });
  router.replace({ pathname, query: updatedQuery }, undefined, { shallow: true });
};

export const updateFilterQueryParam = (router: NextRouter, type: string, values: (number | string)[]) => {
  updateQueryParam(router, type, values?.length > 0 ? values.join(',') : null);
};

export const getFilterKeys = (storefrontFilters?: StorefrontFilter[]): string[] =>
  (storefrontFilters || []).map(({ slug }) => slug);

const parseFilterParam = (filterParam?: string): string[] => {
  if (filterParam) {
    return filterParam.split(',');
  }
  return [];
};

export const parsePageQuery = (
  rawQuery: ParsedUrlQuery,
  category: Nullable<CategoryTreeItemWithSeoAndSort>,
  storefrontFilters?: StorefrontFilter[]
): PageQuery => {
  const sortQuery = rawQuery?.sort as string;
  const sort = isValidSearchProductsSortInput(sortQuery)
    ? sortQuery
    : category?.defaultProductSort || SearchProductsSortInput.Featured;

  const pageQuery: PageQuery = {
    search: (rawQuery?.q || '') as string,
    categoryIds: [],
    sort,
    attributeFilters: {} as Record<string, string[]>,
  };

  // still need this prebuilt storefrontFilters selection to validate filter keys
  // as BigC gql search doesn't do filter key validation :( and will return empty results when invalid
  storefrontFilters?.forEach(({ slug }) => {
    const filterParam = parseFilterParam(rawQuery[slug] as string | undefined);
    if (filterParam.length) {
      if (slug === 'category') {
        pageQuery.categoryIds = filterParam;
      } else {
        pageQuery.attributeFilters[slug] = filterParam;
      }
    }
  });

  return pageQuery;
};

export const getBannerContent = (
  categories: CategoryTreeItem[],
  slug: BannerCategorySlugs
): CategoryTreeItem | undefined => categories.find((cat) => cat.slug === slug);

/**
 * We should only include at most one ad card per page load.
 */
export const getAdCardOffset = ({ cardCount, totalPages }: { cardCount: number; totalPages: number }): 0 | 1 => {
  // no cards
  if (cardCount === 0) {
    return 0;
  }

  // no pages fetched yet AND at least one card
  if (totalPages === 0) {
    return 1;
  }

  // already one card per page
  if (cardCount <= totalPages) {
    return 0;
  }

  return 1;
};

export const getElementsListWithAdCards = ({
  elements,
  tiles,
  position,
  pageSize = PLP_PAGE_SIZE,
}: {
  elements: JSX.Element[];
  tiles: AdCard[];
  position: number;
  pageSize?: number;
}) => {
  const contentsToInsert = tiles
    .map((tile, i) => ({
      item: <ListingAd key={tile.internalName} {...tile} />,
      index: position - 1 + i * pageSize,
    }))
    .filter(({ index }) => index >= 0 && index < elements.length);

  return insertToArray(elements, contentsToInsert);
};

export const getListElementsWithSpacers = ({
  elements,
  spacers,
  position,
  pageSize = PLP_PAGE_SIZE,
}: {
  elements: JSX.Element[];
  spacers: AdSpacer[];
  position: number;
  pageSize?: number;
}) => {
  if (pageSize < 1) {
    return elements;
  }

  if (!elements.length || !spacers.length) {
    return elements;
  }

  const pages = Math.floor(elements.length / pageSize);
  const usableSpacers = spacers.slice(0, pages);

  const inserts = usableSpacers.map((s, i) => ({
    item: <ListingSpacer key={s.internalName} {...s} position={position} pageIndex={i} pageSize={pageSize} />,
    index: elements.length + i,
  }));

  return insertToArray(elements, inserts);
};

export function isBattery(sku: string): boolean {
  // hardcoded for now to save bandwith
  // check only temp applicable for 3 months free battery promotion
  const batterySKUs = ['BA-MATE01', 'BA-PRO01'];
  return batterySKUs.includes(sku);
}

export function shouldDefaultSelectUpsell(locale = i18n.defaultLocale): boolean {
  if (
    [
      SUPPORTED_LOCALES.EN_US,
      SUPPORTED_LOCALES.EN_CA,
      SUPPORTED_LOCALES.FR_CA,
      SUPPORTED_LOCALES.EN_AU,
      SUPPORTED_LOCALES.EN_NZ,
      // SUPPORTED_LOCALES.DE_EU,
      SUPPORTED_LOCALES.EN_EU,
      // SUPPORTED_LOCALES.ES_EU,
      // SUPPORTED_LOCALES.FR_EU,
      // SUPPORTED_LOCALES.IT_EU,
      // SUPPORTED_LOCALES.NL_EU,
      SUPPORTED_LOCALES.EN_GB,
      SUPPORTED_LOCALES.EN_IE,
      SUPPORTED_LOCALES.EN_FR,
      SUPPORTED_LOCALES.EN_DE,
      SUPPORTED_LOCALES.EN_AT,
      SUPPORTED_LOCALES.EN_BE,
      SUPPORTED_LOCALES.EN_FI,
      SUPPORTED_LOCALES.EN_CH,
      SUPPORTED_LOCALES.EN_NL,
    ].includes(locale as any)
  ) {
    return true;
  }

  return false;
}

export function getPercentSavings(price?: number, originalPrice?: number): number | null {
  const pricesAreEqual = price === originalPrice;
  const validPrices = typeof originalPrice === 'number' && typeof price === 'number';
  return !pricesAreEqual && validPrices ? getPercentage(originalPrice - price, originalPrice) : null;
}

export const getAttributes = (product: ComparisonProduct, fieldValues: Record<string, string>[]) => {
  if (!product || !fieldValues || fieldValues.length < 2) {
    return [];
  }

  const customFields = fieldValues[1];

  return [
    ProductAttributeField.RANGE,
    ProductAttributeField.VOLUME,
    ProductAttributeField.BATTERY,
    ProductAttributeField.DIMENSIONS,
    ProductAttributeField.WEIGHT,
  ].map((attr) => ({
    slug: attr in customFields ? attr : '',
    value: customFields[attr] || '',
    label: attr in customFields ? attr : '',
  }));
};

export const getTemplate = (product: Product): ProductViewTemplate =>
  ((product as Product).customFields?.[ProductAttributeField.TEMPLATE] as ProductViewTemplate) ||
  ProductViewTemplate.TILE;

export const generateLoaders = (num: number) =>
  rangeMap(num, (i) => <Skeleton key={`loader-${i}`} className="w-full" height={PLP_IMAGE_SIZE} />);

export const generateLoaderLines = (totalLine: number, shownTotal: number, mobileBreakpoint: string) => {
  const itemsPerLine = window?.innerWidth < parseInt(mobileBreakpoint, 10) ? 2 : 3;
  const num = totalLine * itemsPerLine + (shownTotal % itemsPerLine);
  return generateLoaders(num);
};

export const getProductCustomField = (product: Product | null, field: ProductAttributeField) => {
  if (!product?.customFields) {
    return null;
  }

  return product.customFields[field];
};

/** helpers for PDP related products logic */
const getDisplayColor = (product: Product) => product.customFields?.[ProductAttributeField.PDP_COLOR]?.trim?.();

const isDisplayColorIntersect = (current: Product, compare: Product): boolean => {
  const currentDisplayColor = getDisplayColor(current);
  const compareDisplayColor = getDisplayColor(compare);

  // if any of the products doesn't have display color, return false
  if (!currentDisplayColor || !compareDisplayColor) {
    return false;
  }

  return currentDisplayColor.includes(compareDisplayColor) || compareDisplayColor.includes(currentDisplayColor);
};

const isDisplayColorMatched = (current: Product, compare: Product): boolean => {
  const currentDisplayColor = getDisplayColor(current);
  const compareDisplayColor = getDisplayColor(compare);

  // if any of the products doesn't have display color, return false
  if (!currentDisplayColor || !compareDisplayColor) {
    return false;
  }

  return currentDisplayColor === compareDisplayColor;
};

// BigC gql search doesn't support negative search (as of 08/09/24), hence this manual filtering
const filterRelatedProducts = (current: Product, relatedProducts: Product[], type: RelationType): Product[] =>
  relatedProducts.filter((relatedProduct) => {
    const { sku } = relatedProduct;

    // when fetching variants, filter out products with the same pack-size attribute
    // and if display color intersects (e.g. if current product is "Black", the pack options can include "Black/White" products)
    const validPackSize =
      type === RelationType.VARIANT
        ? getPackSizeFromCustomField(relatedProduct) !== getPackSizeFromCustomField(current) &&
          isDisplayColorIntersect(current, relatedProduct)
        : true;

    // when fetching styles, filter out products with the same color (display color, to simplify the logic)
    // only if the display color is specified
    const validColor =
      type === RelationType.STYLE && getDisplayColor(relatedProduct) && getDisplayColor(current)
        ? getDisplayColor(relatedProduct) !== getDisplayColor(current)
        : true;

    return sku !== current.sku && validPackSize && validColor;
  });

const sortRelatedProducts = (current: Product, relatedProducts: Product[], type: RelationType): Product[] => {
  if (type === RelationType.VARIANT) {
    // sort pack size options by matching display color (pdp_color)
    return relatedProducts.sort((a, b) => {
      const aDisplayColorMatched = isDisplayColorMatched(current, a);
      const bDisplayColorMatched = isDisplayColorMatched(current, b);

      if (bDisplayColorMatched) {
        return 1;
      }

      if (aDisplayColorMatched) {
        return -1;
      }

      return 0;
    });
  }

  return relatedProducts;
};

export const filterAndSortRelatedProducts = (
  current: Product,
  relatedProducts: Product[],
  type: RelationType
): Product[] => {
  const filteredProducts = filterRelatedProducts(current, relatedProducts, type);
  return sortRelatedProducts(current, filteredProducts, type);
};

const renderContentfulOrBigcImage = (
  image: ProductImage | Image,
  isPrimaryImage: boolean = false,
  className?: string
) => {
  if (!image.url) {
    return null;
  }

  const options: ImageOptions = {
    aspectRatio: ImageAspectRatio.PRODUCT_CAROUSEL,
    className,
    objectFit: 'cover',
    loading: isPrimaryImage ? 'eager' : 'lazy',
    priority: !!isPrimaryImage,
  };

  if (extractImageProvider(image.url) === 'contentful') {
    return renderResponsiveImage(getContentfulImgSet(image, undefined), options);
  }

  return renderResponsiveImage(getProductImgSet(image as ProductImage), options);
};

export const renderImageOrVideo = (
  asset: ProductImage | Image | Video,
  isPrimaryImage: boolean = false,
  className?: string
) => {
  if (isVideo(asset.type)) {
    return renderVideo(asset as Video);
  }

  return renderContentfulOrBigcImage(asset as ProductImage | Image, isPrimaryImage, className);
};

export const renderImageThumbnailOrVideo = (
  thumbnail: Image | Video,
  index?: number,
  className?: { video?: string; image?: string }
) => {
  const isLazyLoad = typeof index === 'number' && index > 2;

  if (isVideo(thumbnail.type)) {
    return renderVideo({ ...(thumbnail as Video), className: className?.video, renderAsThumbnail: true });
  }

  return renderImageTag(thumbnail as Image, {
    className: className?.image,
    objectFit: 'cover',
    loading: isLazyLoad ? 'lazy' : 'eager',
  });
};

export function transformAssetsOrder(arr: TransformedAssets[]): TransformedAssets[] {
  // Helper function to safely extract the numeric part from a tag (e.g., "carousel2" -> 2)
  const getNumericTag = (tag: string | null | undefined): number => parseInt(tag?.replace(/\D/g, '') || '0', 10);
  const validAssets = arr.filter((item) => !!item);

  // Separate assets into categories
  const noTagOrOrderItems = validAssets.filter((item) => !item.tag && !item.order);
  const taggedItems = validAssets.filter((item) => item.tag);
  const orderItems = validAssets.filter((item) => item.order);

  // Sort by numeric tag
  const sortedTagItems = taggedItems.sort((a, b) => getNumericTag(a?.tag) - getNumericTag(b?.tag));

  // Sort by 'order' field, defaulting to 0 if order is null
  const sortedOrderItems = orderItems.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));

  // Combine sorted items by interleaving based on numeric tag
  const result: TransformedAssets[] = [];
  let orderIndex = 0;

  sortedTagItems.forEach((taggedItem) => {
    const tagNumber = getNumericTag(taggedItem?.tag);

    // Insert ordered items before the current tagged item
    while (orderIndex < sortedOrderItems.length && (sortedOrderItems[orderIndex].order ?? 0) < tagNumber) {
      result.push(sortedOrderItems[orderIndex]);
      // eslint-disable-next-line no-plusplus
      orderIndex++;
    }

    result.push(taggedItem); // Insert current tagged item
  });

  // Add any remaining ordered items
  result.push(...sortedOrderItems.slice(orderIndex));

  // Return final array with sorted items, followed by untagged and unordered items
  return [...result, ...noTagOrOrderItems];
}

// returns nested content value
export const getNestedContentValue = (
  screen: NtConfigurable<ProductPageContent>,
  contentType: string,
  placeholderFor: string,
  targetKey: string
): boolean =>
  screen?.contentModules
    ?.find((item) => item.contentType === contentType)
    ?.content.tabs.find((tab: any) => tab.content.placeholderFor === placeholderFor)?.content[targetKey];
