import cn from 'classnames';
import { FC, MouseEventHandler, useEffect, useMemo, useRef, useState } from 'react';

import { ImageOrVideoProps } from '@components/common/types/ImageOrVideoItem';
import { useMatchMedia } from '@lib/hooks/useMatchMedia';
import {
  extractImageProvider,
  getContentfulImgSet,
  getContentfulImgUrl,
  renderImage,
  renderResponsiveImage,
} from '@lib/image';
import { renderVideo } from '@lib/video';

const ImageOrVideo: FC<ImageOrVideoProps> = (props) => {
  const {
    type = 'image',
    url,
    mobileUrl,
    alt,
    mobileAlt,
    className,
    containerClassName,
    thumbnail = '',
    enableGifControl = true,
    loading = 'eager',
    renderAsThumbnail = false,
    playOnActive = false,
    srcSet,
    ...options
  } = props;

  const isMobile = useMatchMedia('768px');

  const selectedSource = useMemo(() => {
    if (srcSet) {
      return isMobile ? srcSet.mobile : srcSet.desktop;
    }
    return { type, url, mobileUrl, alt, mobileAlt, className, containerClassName, thumbnail, ...options };
  }, [srcSet, isMobile, type, url, mobileUrl, alt, mobileAlt, className, containerClassName, thumbnail, options]);

  const {
    type: resolvedType,
    url: resolvedUrl,
    mobileUrl: resolvedMobileUrl,
    alt: resolvedAlt,
    mobileAlt: resolvedMobileAlt,
  } = selectedSource;

  const rootClassName = cn('w-full', selectedSource.className);
  const provider = resolvedMobileUrl && resolvedUrl && extractImageProvider(resolvedMobileUrl || resolvedUrl);
  const [isPlaying, setIsPlaying] = useState(true);
  const togglePlay: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsPlaying((prevState) => !prevState);
  };

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [imageDataUrl, setImageDataUrl] = useState<string>('');

  const isGif = useMemo(() => resolvedType === 'image' && resolvedUrl?.includes('.gif'), [resolvedType, resolvedUrl]);

  useEffect(() => {
    const loadImageAndExtractFirstFrame = async (): Promise<void> => {
      if (!resolvedUrl) {
        return;
      }

      const gif = new Image();
      gif.crossOrigin = 'anonymous'; // Set cross-origin attribute to fix Tainted canvases error
      gif.src = resolvedUrl;

      await new Promise((resolve) => {
        gif.onload = resolve;
      });

      const canvas = canvasRef.current;
      if (!canvas) {
        return;
      }
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        return;
      }
      canvas.width = gif.width;
      canvas.height = gif.height;

      ctx.drawImage(gif, 0, 0, gif.width, gif.height);

      // Convert the canvas content to a data URL
      const dataUrl = canvas.toDataURL('image/png');
      setImageDataUrl(dataUrl);
    };

    if (isGif) {
      loadImageAndExtractFirstFrame();
    }
  }, [resolvedUrl, isGif]);

  if (!resolvedUrl) {
    return null;
  }

  if (resolvedType === 'image') {
    if (isGif && enableGifControl) {
      return (
        <div className={cn('items-center flex justify-center relative', containerClassName)}>
          <button
            onClick={togglePlay}
            type="button"
            className="w-full h-full cursor-pointer"
            aria-label="Play/Pause GIF Image"
          >
            {renderImage(
              { url: isPlaying ? resolvedUrl : imageDataUrl, alt: resolvedAlt },
              { className: `${rootClassName} ${resolvedMobileUrl ? 'hidden md:block' : ''}`, loading, ...options }
            )}
            {resolvedMobileUrl &&
              renderImage(
                { url: isPlaying ? resolvedUrl : imageDataUrl, alt: resolvedAlt },
                { className: `${rootClassName} block md:hidden`, loading, ...options }
              )}
          </button>
          <canvas ref={canvasRef} style={{ display: 'none' }} />
        </div>
      );
    }
    if (provider === 'contentful') {
      return renderResponsiveImage(
        getContentfulImgSet(
          { url: resolvedUrl, alt: resolvedAlt },
          { url: resolvedMobileUrl || resolvedUrl, alt: resolvedMobileAlt || resolvedAlt }
        ),
        { className: rootClassName, loading, ...options }
      );
    }
    return renderImage({ url: resolvedUrl, alt: resolvedAlt }, { className: rootClassName, loading, ...options });
  }

  return renderVideo({
    url: resolvedUrl,
    thumbnail: thumbnail ? getContentfulImgUrl(thumbnail, options.width, 'webp') : undefined,
    type: resolvedType,
    className,
    renderAsThumbnail,
    playOnActive,
  });
};

export default ImageOrVideo;
